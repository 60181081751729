<template>
  <div
    :class="[
      'h-[70px] md:h-[65px] z-[51] border-b border-white/[0.08] mt-12 md:mt-0 -mx-3 sm:-mx-8 md:-mx-0 px-3 md:border-b-0 relative md:fixed md:inset-x-0 md:top-0 sm:px-8 md:px-10 md:pt-10 md:bg-gradient-to-b md:from-slate-100 md:to-transparent dark:md:from-darkmode-700',
      props.layout == 'top-menu' && 'dark:md:from-darkmode-800',
      'before:content-[\'\'] before:absolute before:h-[65px] before:inset-0 before:top-0 before:mx-7 before:bg-primary/30 before:mt-3 before:rounded-xl before:hidden before:md:block before:dark:bg-darkmode-600/30',
      'after:content-[\'\'] after:absolute after:inset-0 after:h-[65px] after:mx-3 after:bg-primary after:mt-5 after:rounded-xl after:shadow-md after:hidden after:md:block after:dark:bg-darkmode-600',
    ]"
  >
    <div class="flex items-center h-full justify-between">
      <!-- BEGIN: Logo -->
      <router-link
        :to="{ name: RoutePathNames.HOME }"
        :class="[
          '-intro-x hidden md:flex',
          props.layout == 'side-menu' && 'xl:w-[180px]',
          props.layout == 'simple-menu' && 'xl:w-auto',
          props.layout == 'top-menu' && 'w-auto',
          projectLogo ? 'justify-center' : 'justify-start',
        ]"
      >
        <img
          v-if="projectLogo"
          class="w-full object-contain p-1 max-h-[65px]"
          :src="projectLogo"
        />

        <span
          v-else
          :class="[
            'ml-3 text-lg text-white',
            props.layout == 'side-menu' && 'hidden xl:block',
            props.layout == 'simple-menu' && 'hidden',
          ]"
        >
          {{ projectName }}
        </span>
      </router-link>
      <!-- END: Logo -->

      <!-- BEGIN: Breadcrumb -->
      <div
        class="'h-[45px] md:ml-10 md:border-l border-white/[0.08] dark:border-white/[0.08] mr-auto -intro-x"
      >
        <Breadcrumbs
          v-if="breadcrumbs && breadcrumbs.length"
          :breadcrumbs="breadcrumbs"
          light
          :class="[
            props.layout != 'top-menu' && 'md:pl-6',
            props.layout == 'top-menu' && 'md:pl-10',
          ]"
        />
      </div>
      <!-- END: Breadcrumb -->

      <AppsPopover />

      <ConnectionsStatusDropdown />

      <!-- BEGIN: profile menu -->
      <Menu>
        <Menu.Button class="intro-x flex items-center">
          <div
            v-if="!!me"
            class="w-8 h-8 overflow-hidden rounded-full shadow-lg image-fit zoom-in mr-2"
          >
            <img v-if="userAvatar" :alt="userName" :src="userAvatar" />
            <UserAvatarPlaceholder
              v-else
              :userName="userName"
              :withTooltip="false"
            ></UserAvatarPlaceholder>
          </div>

          <i class="fa-solid fa-angle-down text-light"></i>
        </Menu.Button>

        <Menu.Items
          class="grid gap-2 w-56 mt-px bg-primary/80 before:block before:absolute before:bg-black before:inset-0 before:rounded-md before:z-[-1] text-white capitalize"
        >
          <Menu.Header class="font-normal">
            <div class="font-medium">
              {{ userName }}
            </div>

            <div class="text-xs text-white/70 mt-0.5 dark:text-slate-500">
              {{ me?.role.name }}
            </div>
          </Menu.Header>

          <Menu.Divider class="bg-white/[0.08]" />

          <Menu.Item
            class="bg-success hover:bg-success"
            @click.prevent="navigateTo(`/users/${me?.id}`)"
          >
            {{ $t("profile") }}
          </Menu.Item>

          <Menu.Item class="bg-danger hover:bg-danger" @click.prevent="emit('logout')">
            {{ $t("logout") }}
          </Menu.Item>
        </Menu.Items>
      </Menu>
      <!-- END: profile menu -->
    </div>
  </div>
</template>

<script setup lang="ts">
  import { navigateTo } from "#app";
  import { computed, watch, ref } from "vue";
  import { useRoute } from "vue-router";
  import {
    type BreadcrumbsLink,
    type IBreadcrumbsController,
    Breadcrumbs,
  } from "~/features/breadcrumbs";
  import Menu from "~/shared/ui/Menu";
  import { type IUser, useUserStore } from "~/entities/user";
  import UserAvatarPlaceholder from "~/shared/ui/UserAvatarPlaceholder";
  import { useSettingsStore } from "~/entities/settings";
  import { RoutePathNames } from "~/app/router.options";
  import { getDatastudioAssetUrl } from "~/entities/file";
  import { AppsPopover } from "~/features/apps";
  import { ConnectionsStatusDropdown } from "~/features/show-connections-status";
  import { iocContainer } from "~/inversify.config";
  import { INJECT_SYMBOLS } from "~/service/inversion-of-control/inject-symbols";

  const props = defineProps<{
    layout?: "side-menu" | "simple-menu" | "top-menu";
  }>();

  const emit = defineEmits({
    logout: null,
  });

  const breadcrumbsController = iocContainer.get<IBreadcrumbsController>(
    INJECT_SYMBOLS.BreadcrumbsController,
  );

  const route = useRoute();

  const breadcrumbs = ref<BreadcrumbsLink[] | false>(false);

  watch(
    () => route,
    (newRoute) => {
      breadcrumbs.value = breadcrumbsController.handle(newRoute);
    },
    {
      immediate: true,
      deep: true,
    },
  );

  const userStore = useUserStore();
  const settingsStore = useSettingsStore();

  const me = computed<IUser | undefined>(() => userStore.me);

  const userName = computed(() => {
    if (!me.value) return undefined;
    return `${me.value.getDataProperty("first_name")} ${me.value.getDataProperty(
      "last_name",
    )}`;
  });

  const projectName = computed<string>(
    () => settingsStore.projectSettings?.projectName ?? "",
  );

  const projectLogo = computed<string | undefined>(() => {
    const projectLogoId = settingsStore.projectSettings?.projectLogoId;
    if (!projectLogoId) return undefined;

    return getDatastudioAssetUrl(projectLogoId);
  });

  //toDo: переработать излишнюю реактивность в ChangeUserPersonalData - нужно работать с клонированными данными до финализации .
  const userAvatar = computed<string | false>(() => {
    return !!me.value?.getDataProperty("avatar")
      ? getDatastudioAssetUrl(me.value.getDataProperty("avatar") as string)
      : false;
  });
</script>
