<template>
  <nav class="flex grow" aria-label="breadcrumb">
    <ol
      :class="[
        'flex items-center text-primary dark:text-slate-300',
        { 'text-white/90': light },
      ]"
    >
      <li
        v-for="breadcrumb in breadcrumbs"
        :key="breadcrumb.index"
        :class="getBreadcrumbStyle(breadcrumb)"
      >
        <RouterLink :to="breadcrumb.to">{{ breadcrumb.label }} </RouterLink>
      </li>
    </ol>
  </nav>
</template>

<script setup lang="ts">
  import { type BreadcrumbsLink } from "../lib";

  interface BreadcrumbProps {
    light?: boolean;
    breadcrumbs: BreadcrumbsLink[];
  }

  const props = defineProps<BreadcrumbProps>();

  const getBreadcrumbStyle = (breadcrumb: BreadcrumbsLink) => {
    return [
      breadcrumb.index > 0 && "relative ml-5 pl-0.5",
      breadcrumb &&
        !props.light &&
        breadcrumb.index > 0 &&
        "before:content-[''] before:w-[14px] before:h-[14px] before:bg-bredcrumb-chevron-dark before:bg-[length:100%] before:-ml-[1.125rem] before:absolute before:my-auto before:inset-y-0",
      breadcrumb &&
        props.light &&
        breadcrumb.index > 0 &&
        "before:content-[''] before:w-[14px] before:h-[14px] before:bg-bredcrumb-chevron-light before:bg-[length:100%] before:-ml-[1.125rem] before:absolute before:my-auto before:inset-y-0",
      breadcrumb.index > 0 && "dark:before:bg-bredcrumb-chevron-darkmode",
      breadcrumb &&
        !props.light &&
        breadcrumb.active &&
        "text-slate-800 cursor-text dark:text-slate-400 pointer-events-none",
      breadcrumb &&
        props.light &&
        breadcrumb.active &&
        "text-white/70 pointer-events-none",
    ];
  };
</script>
